import { AvailableSites, type CustomData, ErrorPageUrls, getPage, HttpStatusCode, type Page, type Site, type Market, type Brand, useZmartaAnalytics } from '@ocp-zmarta/content-sdk'
import { addServerState, getLogger, getServerState, setStatusCode, useRoute, type useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { onBeforeMount, onServerPrefetch, ref, type Ref } from 'vue'
import { defineSlugs } from '../utils/define-slugs'
import { getFragmentQuery } from '../utils/get-fragment-query'
import { getVertical } from '../utils/get-vertical'

interface PageDataOptions {
  ctx?: NonNullable<ReturnType<typeof useSSRContext>>
  customData?: CustomData[]
}

interface ReturnPageData {
  pageData: Ref<Page | undefined>
  site: Ref<Site | undefined>
  fragmentCustomQuery?: object
}

function setErrorPageStatusCodeFor (pagePath: string) {
  switch (pagePath) {
    case ErrorPageUrls.NotFound:
      setStatusCode(HttpStatusCode.NotFound)
      break
    case ErrorPageUrls.InternalServerError:
      setStatusCode(HttpStatusCode.InternalServerError)
      break
  }
}

export default function ({ ctx, customData }: PageDataOptions = {}): ReturnPageData {
  const route = useRoute()
  const pageData = ref<Page>()
  const site = ref<Site>()
  const fragmentCustomQuery = ref()
  const logger = getLogger()

  onServerPrefetch(async () => {
    const path = route?.pathname?.replace('/app-pages', '') ?? ''
    site.value = `${ctx?.brand}.${ctx?.market}` as Site

    if (!path) logger.error('path is undefined')
    if (!site.value) logger.error('site is undefined')

    try {
      pageData.value = await getPage({ path, site: site.value, locale: ctx?.locale }, { customData })

      if (pageData.value?.content?.hero?.fragment) pageData.value.content.hero.fragment.timeout = 10000

      // temp set hero fragment type/renderMode to 'server' until deployed to prod. then we can change it in contentful.
      if (pageData.value?.content?.hero?.fragment?.type) pageData.value.content.hero.fragment.type = 'server'
      if (pageData.value?.content?.hero?.fragment?.renderMode) pageData.value.content.hero.fragment.renderMode = 'server'

      fragmentCustomQuery.value = getFragmentQuery(ctx!, pageData)
    } catch (error: any) {
      setErrorPageStatusCodeFor('/500-error')
      logger.error('error in getPage fetcher', { error, path, site: site.value })
    }

    setErrorPageStatusCodeFor(pageData.value?.path ?? '')

    // Set the values on the server state
    addServerState('page-data', pageData.value)
    addServerState('site', site.value)
    addServerState('locale', ctx?.locale)
    addServerState('path', path)
    addServerState('query', ctx?.query)
    addServerState('device', ctx?.device)
    addServerState('config', ctx?.config)
    addServerState('vertical', getVertical(pageData.value?.vertical))
    addServerState('constants', ctx?.constants)
    addServerState('has-fragment', !!pageData?.value?.content?.hero?.fragment)
  })

  onBeforeMount(() => {
    pageData.value = getServerState('page-data')!
    site.value = getServerState('site') ?? '' as Site

    if (AvailableSites.includes(site.value)) {
      const market = site.value.split('.').pop() as Market
      const brand = site.value.split('.').shift()! as Brand

      const ZGA = useZmartaAnalytics({
        brand,
        market
      })

      const serverState: { url: string | undefined, previousUrl: string | undefined } = getServerState('query')!
      const url = serverState.url ?? route?.pathname
      const previousUrl = serverState.previousUrl ?? ''

      if (url === ErrorPageUrls.NotFound) {
        void ZGA.event.page.error404Page({ url, previousUrl })
      }

      const slugs = defineSlugs({ market, data: pageData.value, config: getServerState('config') })
      sessionStorage.setItem('zmarta_session', JSON.stringify({ zmartaSession: { slugs } }))

      fragmentCustomQuery.value = getFragmentQuery(ctx!, pageData)
    }
  })

  return {
    pageData,
    site,
    fragmentCustomQuery
  }
}
